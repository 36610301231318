import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout/layout'
import styled from 'styled-components'
import Rtf from '../components/Rtf/Article'
import OrbImage from '../components/OrbImage';
import { mq } from '../styles/theme';

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return (
      <>
      </>
    )
  }

  return (
    <Layout>
      <Wrapper>
        <OrbElementTop>
          <OrbImage type={'nocolor-large'}/>
        </OrbElementTop>
        <OrbElementBottom>
          <OrbImage type={'nocolor-large'}/>
        </OrbElementBottom>
        <RtfContainer>
          <Rtf
            copy='
              <h1>404</h1>
              <p>That’s odd. This page appears to be missing. Head to our <a href="/">Homepage</a>.</p>
            '
          />
        </RtfContainer>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  margin: auto;
  position: relative;
  overflow: hidden;
`

const RtfContainer = styled.div`
  max-inline-size: 350px;
`

const OrbElementTop = styled.div`
  display: none;
  top: 0;
  left: 0;
  width: 500px;
  position: absolute;
  transform: translate(-50%,0%);

  ${mq.desktop} {
    display: block;
  }
`

const OrbElementBottom = styled.div`
  display: none;
  bottom: 0;
  right: 0;
  width: 600px;
  position: absolute;
  transform: translate(60%, 10%);

  ${mq.desktop} {
    display: block;
  }
`
